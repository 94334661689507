h1,
h2,
h3,
h4,
h5,
h6,
p, 
li, 
form, 
*,
button,
span
 {
  font-family: 'Bitter',serif}

/*=========================
  Navbar
 ================= */

.logo {
  height: 59px;
  width: 230px;
}

.flag {
  height: 17px;
  width: 25px;
  margin-right: 0.4rem;
}

.navbar-light .navbar-nav .nav-link {
  color: #b91916;
}

a.red {
  text-decoration: none;
}
a.nonDecoration {
  text-decoration: none;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  color: #b91916;
  text-decoration: none;
}

/*=========================
  footer
 ================= */

a .red {
  color: #b91916;
  text-decoration: none;
}

div.d-flex.red {
  background-color: #b91916;
  color: #b91916;
}

footer li svg {
  margin-right: 1rem;
  color: #5f5f5f;
}

/*=========================
  social icons
 ================= */

/* footer social icons */
ul.social-network {
  list-style: none;
  display: inline;
  margin-left: 0 !important;
  padding: 0;
}
ul.social-network li {
  display: inline;
  margin: 0 5px;
}

/* footer social icons */

a.socialIcon:hover,
.socialHoverClass {
  color: #44bcdd;
}

.social-circle li a {
  display: inline-block;
  position: relative;
  margin: 0px auto 1rem auto;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  background-color: grey;
  color: #ffffff;
}
.social-circle li svg {
  margin: 0.95rem 0 0 0;
  line-height: 50px;
  text-align: center;
}

/*=========================
  Carousel
 ================= */

.bs-slider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

h1.title {
  font-size: 5rem;
}

@media (max-width: 400px) {
  h1.title {
    font-size: 1.2rem;
    margin-top: -1.5rem;
  }
  .jumbotron h2 {
    font-size: 1rem;
  }
  .jumbotron {
    padding: 0;
  }
}

@media (max-width: 1300px) {
  h1.title {
    font-size: 4rem;
  }
  .jumbotron h2 {
    font-size: 1.5rem;
  }
  .jumbotron {
    padding: 0;
  }
  .titleAlign {
    top: 10%;
  }
}

@media (max-width: 1000px) {
  h1.title {
    font-size: 2.3rem;
  }
  .jumbotron h2 {
    font-size: 1.2rem;
  }
  .jumbotron {
    padding: 0;
  }
  .titleAlign {
    top: 0%;
  }
}

.titleAlign {
  top: 20%;
}

/*=========================
  Main Icons
 ================= */

.red {
  color: #6c1c1e;
}

/* Red subtitles -   */
.redDark {
  color: #6c1c1e;
  font-family: 'Bitter',serif;
}
svg.red {
  color: #6c1c1e;
  margin-right: 0.5rem;
}

svg.redLink {
  color: #b91916;
  margin: 0rem 0.5rem 0 0;
}

.red h2 {
  padding-top: 2rem;
  color: #6c1c1e;
}
.red h6 {
  color: grey;
}

@media (max-width: 730px) {
  .boxes h1 {
    font-size: 3rem;
  }

  .red h2 {
    font-size: 1.5rem;
  }

  .general.main h2,
  h4,
  h5 {
    font-size: 1.1rem;
  }
}

.black {
  color: #313437;
  font-weight: bold;
}

ul.list {
  list-style: none; /* Remove default bullets */
}

ul.list li::before {
  content: '\276F'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #b91916; /* Change the color */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/*=========================
  General Boxes
 ================= */

.general {
  height: 600px;
  background: transparent;
  position: relative;
  overflow: hidden;
}

.jumbotronGeneral {
  background-size: cover;
  background-position: center;
  position: absolute;
  height: 130%;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: -100;
}

.general .box {
  word-wrap: break-word;
  display: inline-block;
  background-color: rgba(6, 6, 6, 0.5);
  height: auto;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  padding: 0.6em;
  border-radius: 0.25em;
  color: white;
  vertical-align: baseline;
  margin: 2rem;
}

.general .box h1 {
  display: inline-block;
  color: rgb(249, 248, 248);
  font-family: 'Bitter',serif;
  font-size: 3.5rem;
  margin: 1rem 2rem 1rem 1rem;
  padding-top: 3rem;
}

@media (max-width: 730px) {
  .general {
    height: 400px;
    background: transparent;
    position: relative;
    overflow: hidden;
  }

  .general div .box {
    /* display: inline-block; */
    color: rgb(249, 248, 248);
    font-family: 'Bitter',serif;
    margin: 2rem 0.5rem;
    padding: 0.5rem;
    max-width: 95%;
  }

  .general .box h1 {
    font-size: 1.5rem;
  }
  .general .box h3 {
    font-size: 1.2rem;
    padding: 0.5rem;
  }

  .detail {
    margin: 0;
  }

  .detail div h4 {
    font-size: 1.2rem;
  }
  .detail div h5 {
    font-size: 1rem;
  }
}

.general.main {
  height: 500px;
  background: transparent;
  position: relative;
  overflow: hidden;
}
.general.main .box {
  padding: 1rem;
  margin-top: 0rem;
}

.general.main div .box h2,
.general.main div .box h4,
.general.main div .box h4,
.general.main div .box h5 {
  color: rgb(249, 248, 248);
  font-family: 'Bitter',serif;
  margin: 1rem 2rem 1rem 1rem;
  padding-top: 0.3rem;
}

/*=========================
 BACKGROUNDS 
 ================= */

.imgAbout {
  background-image: url('/assets/img/about/about_nosotros.jpg');
}
.imgWork {
  background-image: url('/assets/img/about/about_work.jpg');
  height: 200%;
}
.imgClient {
  background-image: url('/assets/img/about/about_client.jpg');
}

.imgAses {
  background-image: url('/assets/img/about/about_asesoria.jpg');
  height: 100%;
  opacity: 1.5;
}

.imgTeam {
  background-image: url('/assets/img/about/about_team.jpeg');
}

.imgOffice {
  background-image: url('/assets/img/about/about_office.jpg');
  height: 100%;
}

.imgMain {
  background-image: url('/assets/img/04.jpg');
  height: 100%;
  opacity: 1.2;
}

.imgEntibacion {
  background-image: url('/assets/img/entibacion01.png');
}

.imgMoldaje {
  background-image: url('/assets/img/moldaje01.jpg');
  opacity: 0.94;
}

.imgEncofrado {
  background-image: url('/assets/img/encofrado01.jpg');
  height: 100%;
  opacity: 0.94;
}
.imgSoportacion {
  background-image: url('/assets/img/soportacion01.jpg');
  height: 100%;
}
.imgSoluciones {
  background-image: url('/assets/img/soluciones01.jpg');
  height: 100%;
}

.imgGeotecnia {
  background-image: url('/assets/img/geotecnia01.jpg');
}

/*=========================
 cards 
 ================= */

.card {
  border: none;
}

/*=========================
 TABLE
 ================= */

th.dimentions {
  color: red;
}

table thead {
  font-size: 1.3rem;
  border: none;
}
table tbody {
  font-size: 1.1rem;
  border: none;
}

.table th,
.table tr,
.table td,
.table thead th {
  border: none;
}

@media (max-width: 400px) {
  table thead {
    font-size: 1rem;
    border: none;
  }
  table tbody {
    font-size: 0.8rem;
    border: none;
  }
}

/*=========================
 Margins
 ================= */

.mt-n10 {
  margin-top: -5.5rem;
}

/*=========================
 Gallery
 ================= */

span.center {
  padding-left: 40%;
}

/*=========================
 Photo Gallery
 ================= */

.gallery {
  display: block;
  min-height: 1px;
  width: 100%;
  border: none;
  overflow: auto;
}

.btn-group {
  align-self: end;
}

.btn-toolbar {
  justify-content: left;
}

@media (max-width: 730px) {
  .btn-group > .btn-group:not(:last-child) > .btn,
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border: none;
    width: 8rem;
    text-align: left;
  }
}

.ReactGridGallery_tile-bottom-bar span {
  color: white;
}

.ReactGridGallery_tile-bottom-bar div span {
  color: white;
}

/*=========================
Disponibilidad
 ================= */
